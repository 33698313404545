:root {
  --color-primary: #415a77ff;
  --color-secondary: #415a77ff;
  --color-secondary-darker: #737373;
  --color-primary-bg: #e0e1ddff;
  --color-selected: #d33e43;
  --color-selected-secondary: rgba(213, 64, 31, 255);

  --zenix-light-brown: #888b76;
  --zenix-light-brown2: #b8bba5;
  --zenix-light-blue: #678897;
  --zenix-dark-brown: #573f25;
  --zenix-dark-blue: #4a626e;
  --zenix-white: #fffffc;
  --zenix-grey: #adb7c0;
  --zenix-black: #000000;

  --gradient-primary: linear-gradient(to top left, #39b385, #9be15d);
  --gradient-secondary: linear-gradient(to top left, #ffb003, #ffcb03);
  --rich-black-fogra-29: #0d1b2aff;
  --oxford-blue: #1b263bff;
  --bdazzled-blue: #415a77ff;
  --shadow-blue: #778da9ff;
}
