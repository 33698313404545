:root {
  --color-primary: #415a77;
  --color-secondary: #415a77;
  --color-secondary-darker: #737373;
  --color-primary-bg: #e0e1dd;
  --color-selected: #d33e43;
  --color-selected-secondary: #d5401f;
  --zenix-light-brown: #888b76;
  --zenix-light-brown2: #b8bba5;
  --zenix-light-blue: #678897;
  --zenix-dark-brown: #573f25;
  --zenix-dark-blue: #4a626e;
  --zenix-white: #fffffc;
  --zenix-grey: #adb7c0;
  --zenix-black: #000;
  --gradient-primary: linear-gradient(to top left, #39b385, #9be15d);
  --gradient-secondary: linear-gradient(to top left, #ffb003, #ffcb03);
  --rich-black-fogra-29: #0d1b2a;
  --oxford-blue: #1b263b;
  --bdazzled-blue: #415a77;
  --shadow-blue: #778da9;
}

body {
  vertical-align: baseline;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: Zen Maru Gothic, sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  min-height: 100% !important;
}

* {
  box-sizing: inherit;
}

h1 {
  font-size: 2rem;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

canvas {
  display: block;
}

bld {
  color: var(--zenix-light-brown2);
  font-size: 1.7rem;
  font-weight: 900;
}

bld2 {
  color: var(--color-primary-bg);
  font-size: 2rem;
  font-weight: bold;
}

#particles-js {
  width: 100%;
  height: 100rem;
  background-color: #678897;
  background-position: 50%;
  background-repeat: repeat;
  background-size: contain;
  position: relative;
}

#content-box {
  z-index: 100;
  color: #fff;
  background: #0006;
  border-radius: 1.3rem;
  padding: 1rem 1.8rem 4rem 1.5rem;
  position: absolute;
  top: 5%;
  right: 50%;
  overflow: auto;
  transform: translate(50%);
}

.logo {
  width: 35rem;
}

.pdiv {
  background-color: #ffffff14;
  border-radius: 2.9rem;
  padding: 0;
}

.spanBig {
  cursor: default;
  color: #e0e1dd;
  background-color: #ffffff1a;
  border-radius: 1rem;
  padding: 1.5rem;
  font-size: 3rem;
  line-height: 2.5;
}

.header-df {
  justify-content: space-between;
  display: flex;
}

@keyframes rotateFinite {
  0% {
    transform: rotate(0);
  }

  80% {
    transform: rotate(420deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes shrink {
  0% {
    transform: scale(.1);
  }

  20% {
    transform: scale(.2);
  }

  30% {
    transform: scale(.1);
  }

  40% {
    transform: scale(.2);
  }

  50% {
    transform: scale(.1);
  }

  90% {
    transform: scale(1);
  }

  95% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }

  90% {
    transform: translateX(150%);
  }

  100% {
    transform: translateX(100%);
  }
}

.rotate {
  transition: all 1s;
  animation: 1s ease-out rotateFinite;
}

.vanish {
  transition: all 1s ease-in-out;
}

.content-container {
  width: 90rem;
  min-height: 20rem;
  position: relative;
  overflow: hidden;
}

.pic {
  justify-content: space-between;
  display: flex;
}

.obr2 {
  width: 11rem;
  min-height: 100%;
  filter: sepia(19%);
  position: absolute;
  top: 0;
  right: 0;
}

.obr {
  width: 11rem;
  opacity: 0;
}

.textik {
  padding: 2rem;
}

.boxes {
  width: 90rem;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translate(0%);
}

.overall-container {
  justify-content: center;
  gap: 2.2rem;
  padding-top: 1.5rem;
  display: flex;
}

.arrow_div {
  align-self: top;
  text-align: left;
  min-width: 1.5rem;
  padding: .5rem 0 0;
}

.hidden {
  display: none;
}

.greyed {
  filter: opacity(0%) !important;
}

.arrow {
  filter: opacity(80%);
}

.spanSmall {
  cursor: pointer;
  color: var(--zenix-light-blue);
  border-radius: 1.5rem;
  font-size: 1.9rem;
}

.tab {
  margin: 0 0 0 0;
  padding: 1.2rem;
  font-weight: bold;
  line-height: 2rem;
  transition: all .5s;
  animation: .5s ease-in-out shrink;
}

.flags {
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.languages {
  filter: sepia(69%);
}

.tab_container, .text_container {
  gap: 0;
  margin: 0;
  display: flex;
  position: relative;
}

.clona-svg10 {
  cursor: pointer;
  height: 2.7rem;
  width: 2.7rem;
  filter: opacity(40%);
  z-index: 200;
  background-color: #415a77;
  border: 0 solid #415a77;
  border-radius: 50%;
  position: absolute;
}

.svg-10 {
  cursor: pointer;
  height: 2.7rem;
  width: 2.7rem;
  border: .2rem solid var(--color-primary-bg);
  border-radius: 50%;
  position: absolute;
}

.clona-all {
  min-width: 2.7rem;
  display: block;
  position: relative;
}

.hidden_content_box {
  filter: opacity(0%);
  position: relative !important;
}

@media (max-width: 1200px) {
  #content-box {
    max-width: 900px;
  }

  .content-container, .boxes {
    width: 600px;
  }

  .obr, .obr2 {
    width: 80px;
  }

  #particles-js {
    height: 1500px;
  }
}

@media (max-width: 800px) {
  #content-box {
    width: 100%;
    top: 0;
    left: 0;
    transform: translate(0%);
  }

  .content-container, .boxes {
    width: 100%;
  }

  .obr, .obr2 {
    width: 0;
  }

  .spanBig {
    padding: 1rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  .tab {
    margin: 0 0 0 0;
    padding: .8rem;
    line-height: 1.2rem;
    transition: all .5s;
    animation: .5s ease-in-out shrink;
  }

  .arrow_div {
    display: none;
  }

  .overall-container {
    justify-content: center;
    gap: 0;
    padding-top: 0;
  }

  #particles-js {
    height: 1500px;
  }
}

/*# sourceMappingURL=index.91b9398e.css.map */
